import { HTML5Backend } from 'react-dnd-html5-backend'
import { PointerTransition, TouchTransition } from 'react-dnd-multi-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

export const backends = [
  {
    id: 'html5',
    backend: HTML5Backend,
    transition: PointerTransition,
  },
  {
    id: 'touch',
    backend: TouchBackend,
    options: { enableMouseEvents: true },
    preview: true,
    transition: TouchTransition,
  },
]

export const isTouchScreen = window.matchMedia('(pointer: coarse)').matches
