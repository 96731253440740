import React from 'react'
import { DndProvider } from 'react-dnd'
import { MultiBackend } from 'react-dnd-multi-backend'
import 'react-material-symbols/outlined'
import { Provider as ReduxProvider } from 'react-redux'
import AuthProvider from '@/provider/authProvider'
import { Routes } from '@/routes'
import { store } from '@/store'
import './index.css'
import './iziToast.css'
import './quill.css'
import './general-override.css'

import { HelmetProvider } from 'react-helmet-async'
import { PersistGate } from 'redux-persist/integration/react'
import { PostHogProviderWrapper } from '@/provider'
import { persistor } from '@/store'
import packageInfo from '../package.json'
import MainThemeProvider from './theme/MainThemeProvider'
import { CacheBuster } from './components/cache-buster/CacheBuster'
import { backends } from './utils/dndBackends'

const isProduction = process.env.NODE_ENV === 'production'

export const App: React.FC = () => {
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction}
      isVerboseMode={false}
    >
      <PostHogProviderWrapper>
        <HelmetProvider>
          <MainThemeProvider>
            <ReduxProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <AuthProvider>
                  <DndProvider backend={MultiBackend} options={{ backends }}>
                    <Routes />
                  </DndProvider>
                </AuthProvider>
              </PersistGate>
            </ReduxProvider>
          </MainThemeProvider>
        </HelmetProvider>
      </PostHogProviderWrapper>
    </CacheBuster>
  )
}

export default App
